import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static values = {
    create: Boolean,
    maxItems: Number,
    itemIcon: String,
  };
  static classes = [ "control" ];

  connect() {
    const maxItems = this.hasMaxItemsValue
      ? { maxItems: this.maxItemsValue }
      : {};
    const itemIconValue = this.itemIconValue;
    this.select = new TomSelect(this.element, {
      ...maxItems,
      hidePlaceholder: true,
      create: this.createValue ?? false,
      plugins: {
        remove_button: {
          label: "",
          title: "Remove this item",
        },
      },
      persist: false,
      closeAfterSelect: true,
      onChange: this.onChange.bind(this),
      render: {
        no_results: function (data, escape) {
          return (
            '<div class="no-results p-2">No results found for "' +
            escape(data.input) +
            '"</div>'
          );
        },
        item: function (data, escape) {
          const iconHtml = itemIconValue
            ? `<span class="${itemIconValue} icon icon-xs mr-1 text-purple-500"></span>`
            : "";

          return "<div>" + iconHtml + escape(data.text) + "</div>";
        },
        option: function (data, escape) {
          const { text, disabled, suffixLabel, suffixColorClass } = {
            suffixColorClass: "info",
            ...data,
          };

          const defaultOptionTemplate = `<div>${escape(text)}</div>`;
          const extendedOptionTemplate = `<div class="flex justify-between" style="opacity: 1"><span class="${
            disabled ? "opacity-50" : ""
          }">${escape(
            text
          )}</span><div class="tag ${suffixColorClass}">${escape(
            suffixLabel
          )}</div></div>`;

          return suffixLabel ? extendedOptionTemplate : defaultOptionTemplate;
        },
      },
    });
    this.applyControlClass();
  }

  applyControlClass() {
    if (this.controlClasses.length > 0) {
      let tsControlDiv = this.element.nextElementSibling.querySelector(".ts-control");
      tsControlDiv.classList.add(this.controlClass);
    }
  }

  onChange() {
    if (this.element.dataset.toggleableOption) {
      const [option, targetId] =
        this.element.dataset.toggleableOption.split("#");

      if (this.select.getValue() === option) {
        document.getElementById(targetId).classList.remove("hidden");
      } else {
        document.getElementById(targetId).classList.add("hidden");
      }
    }
  }

  updateOptions(newOptions) {
    this.select.clearOptions();
    newOptions.forEach((option) => {
      this.select.addOption(option);
    });
    this.select.refreshOptions(false);
  }

  removeOption(optionValue) {
    this.select.removeOption(optionValue);
    this.select.refreshOptions(false);
  }

  disconnect() {
    this.select.destroy();
  }
}
